import Vue from 'vue'
import Vuex from 'vuex'
import axios from '@axios'
import config from '../../config'

let BASE_URL = config.BASE_URL;
const API_BASE_PLANS = BASE_URL + '/plans'

Vue.use(Vuex)

export default new Vuex.Store({
    namespace: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        async fetchPlans() {
            return new Promise((resolve, reject) => {
                axios
                    .get(`${API_BASE_PLANS}/fetchPlans`)
                    .then((plans) => {
                        this.commit('getPlans', plans);
                        resolve(plans)
                    })
                    .catch(error => reject(error))
            })
        },
    },
    strict: process.env.DEV,
})
